<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">班级抽查</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="培训机构" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">培训机构:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>

            <!-- <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button> -->
          </div>
          <div class="searchbox" style="margin-bottom:5px">
            <span title="行政区划" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :props="{emitPath:false,checkStrictly: true}"
                v-model="areaId"
                :options="cityTree"
                size="small"
                clearable
                filterable
                @change="handleCascader"
              ></el-cascader>
            </span>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                :props="{emitPath:false,value:'id',label:'label',children:'children',checkStrictly: true}"
                v-model="trainTypeId"
                size="small"
                clearable
                :options="trainTypeList"
                @change="handleTrainType"
              ></el-cascader>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="BatchProcessing()">批量处理</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              @selection-change="handleSelectionChange"
              stripe
            >
              <el-table-column type="selection" align="center" width="55"></el-table-column>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="班级编码"
                align="left"
                prop="projectCode"
                show-overflow-tooltip
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeName"
              >
                <template slot-scope="scope">{{ scope.row.trainTypeName || "--" }}</template>
              </el-table-column>
              <el-table-column label="所属机构" align="left" prop="compName" show-overflow-tooltip />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassSpotCheck/ClassSpotCheck",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      projectCode: "",
      projectName: "",
      cityTree: [],
      areaId: "",
      CompanyList: [],
      compId: "",
      trainTypeList: [],
      trainTypeId: "",
      multipleSelection: [],
      projectIds: []
    };
  },
  created() {
    this.getcity(); // 行政区划
    this.getTraintype(); //培训类型
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    })
  },
  methods: {
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then(res => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then(res => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      this.doFetch({
        url: "/run/project/generate/page",
        params,
        pageNum
      });
    },
    // 资源提供者1
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    handleSelectionChange(val) {
      //单选框s
      this.multipleSelection = val;
      // console.log(val)
    },
    /* 批量处理 */
    BatchProcessing() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: "error",
          message: "请选中至少一条记录!"
        });
        return false;
      } else {
        let projectIds = [];
        this.multipleSelection.map(t => {
          projectIds.push(t.projectId);
        });
        this.$post("/run/project/generate/spotCheckReport", {
          projectIds
        })
          .then(res => {
            if (res.status == "0") {
              console.log(res.data.taskId);
              if (!this.downloadItems.includes(res.data.taskId)) {
                this.$store.dispatch("pushDownloadItems", res.data.taskId);
              } else {
                this.$message.warning(
                  "[" + res.data.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          })
          .catch(err => {
            return;
          });
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less"></style>
